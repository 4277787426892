<template>
  <v-card class="ma-10">
    <v-card>
      <v-toolbar
        dark
        style="background-color:#00C853"
      >
        <v-toolbar-title>
          NOVO LOGIN *
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
    </v-card>
    <v-card>
      <v-form>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="7"
            >
              <v-text-field
                v-model="usuario.nome"
                label="Nome"
                :error-messages="nomeErrors"
                :counter="100"
                required
                @input="$v.usuario.nome.$touch()"
                @blur="$v.usuario.nome.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="7"
            >
              <v-text-field
                v-model="usuario.email"
                label="Email"
                :error-messages="emailErrors"
                :counter="100"
                required
                @input="$v.usuario.senha.$touch()"
                @blur="$v.usuario.senha.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              md="5"
            >
              <v-text-field
                v-model="usuario.senha"
                label="Senha"
                :counter="10"
                :error-messages="senhaErrors"
                required
                type="password"
                @input="$v.usuario.senha.$touch()"
                @blur="$v.usuario.senha.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              md="5"
            >
              <v-text-field
                v-model="usuario.repetirsenha"
                label="Confirmar Senha"
                :error-messages="repetirsenhaErrors"
                :counter="10"
                required
                type="password"
                @input="$v.usuario.repetirsenha.$touch()"
                @blur="$v.usuario.repetirsenha.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-btn
                text
                style="margin-top:20px; text-align: left; font-size:8pt;"
                @click="onVoltar"
              >
                <v-icon
                  right
                  dark
                  style="padding-right: 18px;color:#00C853;"
                >
                  mdi-arrow-left
                </v-icon>
                Voltar
              </v-btn>
            </v-col>
            <v-col
              cols="8"
              class="d-flex justify-start"
            >
              <v-btn
                outlined
                style="background-color:#00C853; color:#fff; margin-top:15px;"
                @click="onSalvar"
              >
                SALVAR
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-card>
</template>
<script>
  import { validationMixin } from 'vuelidate'
  import { required, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators'
  import firebase from 'firebase'
  const db = firebase.firestore()
  const dbAuth = firebase.auth()

  export default {
    mixins: [validationMixin],
    validations: {
      usuario: {
        nome: { required, maxLength: maxLength(60) },
        email: { required, email, maxLength: maxLength(200) },
        senha: { required, minLength: minLength(6), maxLength: maxLength(10) },
        repetirsenha: { required, sameAsPassword: sameAs('senha') },
      },
    },
    data () {
      return {
        usuario: {
          nome: '',
          senha: '',
          email: '',
          repetirsenha: '',
        },
        senha: '',
        confirmarSenha: '',
        valid: false,
        carregarDados: false,
        visibledPerfil: true,
      }
    },
    computed: {
      nomeErrors () {
        const errors = []
        if (!this.$v.usuario.nome.$dirty) return errors
        !this.$v.usuario.nome.maxLength && errors.push('Tamanho maximo de 60 caracteres')
        !this.$v.usuario.nome.required && errors.push('Campo Obrigatorio')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.usuario.email.$dirty) return errors
        !this.$v.usuario.email.email && errors.push('Deve ser um e-mail válido')
        !this.$v.usuario.email.required && errors.push('E-mail é obrigatório')
        return errors
      },
      senhaErrors () {
        const errors = []
        if (!this.$v.usuario.senha.$dirty) return errors
        !this.$v.usuario.senha.minLength && errors.push('Tamanho minimo de 6 caracteres')
        !this.$v.usuario.senha.maxLength && errors.push('Tamanho maximo de 10 caracteres')
        !this.$v.usuario.senha.required && errors.push('Campo Obrigatorio')
        return errors
      },
      repetirsenhaErrors () {
        const errors = []
        if (!this.$v.usuario.repetirsenha.$dirty) return errors
        !this.$v.usuario.repetirsenha.sameAsPassword && errors.push('Senha não é identica!')
        return errors
      },
    },
    async created () {
      setTimeout(() => {
        this.carregarDados = true
      }, 1000)
    },
    methods: {
      onSair () {
        this.$emit('onSairCadastro')
      },
      async onSalvar () {
        this.usuario.email = this.usuario.email.trim()
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.$store.commit('setSplashScreen', true)
          var data = this
          dbAuth.createUserWithEmailAndPassword(this.usuario.email, this.usuario.senha).then((dados) => {
            db.collection('usuarios').doc(dados.user.uid).set({
              nome: data.usuario.nome,
              stusuario: 'L',
              email: data.usuario.email,
            }).catch((error) => {
              this.$swal({
                icon: 'warning',
                text: error,
              })
            })
            this.$store.commit('setSplashScreen', false)
          }).then(() => {
            this.$swal({
              icon: 'success',
              text: 'Conta criada com sucesso!',
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) this.$router.replace('/')
            })
            this.$store.commit('setSplashScreen', false)
          }).catch((error) => {
            this.$store.commit('setSplashScreen', false)
            if (error.code === 'auth/email-already-in-use') {
              this.$swal({
                icon: 'warning',
                text: 'E-mail já esta em uso!',
              })
            } else if (error.code === 'auth/email-already-exists') {
              this.$swal({
                icon: 'warning',
                text: 'E-mail já cadastrado!',
              })
            } else if (error.code === 'auth/invalid-argument') {
              this.$swal({
                icon: 'warning',
                text: error,
              })
            }
          })
        }
        // else {
        //   requestAnimationFrame(() => {
        //     this.$refs.observer.reset()
        //   })
        // }
      },
      onVoltar () {
        this.$emit('onVoltar')
      },
      onRemoverCaracteresEspeciais () {
        var nome = this.user.nome.replace(/[ÀÁÂÃÄÅ]/g, 'A')
        nome = nome.replace(/[àáâãäå]/g, 'a')
        nome = nome.replace(/[ÈÉÊË]/g, 'E')
        nome = nome.replace(/[éèê]/g, 'e')
        nome = nome.replace(/[Ç]/g, 'C')
        nome = nome.replace(/[òóôõö]/g, 'o')
        nome = nome.replace(/[ÒÓÔÕÖ]/g, 'O')
        nome = nome.replace(/[íì]/g, 'i')
        nome = nome.replace(/[ÍÌ]/g, 'i')
        this.user.nome = nome.toUpperCase()
      },
    },
  }
</script>
